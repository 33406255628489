<template>
  <div>
    <div
      style="height: calc(100vh - 145px); overflow-y: auto; overflow-x: hidden"
    >
      <PosList v-if="isActiveTab('pos-list')" />
      <WorkingTimeList v-if="isActiveTab('working-time-list')" />
      <DayOffList v-if="isActiveTab('day-off-list')" />
    </div>
    <v-divider class="mt-2"></v-divider>
    <div
      class="pt-2 pb-4 pb-md-2 white-space-nowrap overflow-x-auto overflow-y-hidden"
    >
      <v-btn
        :color="isActiveTab('pos-list') ? 'primary' : 'default'"
        small
        class="mr-2"
        @click="changeTab('pos-list')"
        >{{ $t("labels.pos") }}
      </v-btn>
      <v-btn
        :color="isActiveTab('working-time-list') ? 'primary' : 'default'"
        small
        class="mr-2"
        @click="changeTab('working-time-list')"
        >{{ $t("labels.working_time") }}
      </v-btn>
      <v-btn
        :color="isActiveTab('day-off-list') ? 'primary' : 'default'"
        small
        class="mr-2"
        @click="changeTab('day-off-list')"
        >{{ $t("labels.day_off") }}
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "Index",
  components: {
    PosList: () => import("@/components/pos/PosList"),
    WorkingTimeList: () => import("@/components/pos/WorkingTimeList"),
    DayOffList: () => import("@/components/pos/DayOffList"),
  },
  data: () => ({
    isLoading: false,
    tab: "pos-list",
  }),
  computed: {},
  methods: {
    changeTab(tab) {
      this.tab = tab;
    },
    isActiveTab(tab) {
      return this.tab === tab;
    },
  },
};
</script>

<style scoped></style>
